import React from "react"
import EventLayout from "../../components/layouts/eventLayout"

const ProgramsIndexPage = props => (
  <EventLayout pageTitle="Programs & Events">
    <p>Stuff goes here...</p>
  </EventLayout>
)

export default ProgramsIndexPage
